export const validator = { // async-validator 所有验证规则 通用的独立出来
  phone: [
    {
      required: true,
      validator: (rule, value, callback) => {
        if (!value) {
          callback('请输入手机号码');
        } else if (/^[1][0-9]{10}$/.test(value)) {
          callback();
        } else {
          callback('请输入正确的手机号码');
        }
      }, trigger: "blur"
    }
  ],
  name: [
    { required: true, message: "用户名不能为空", trigger: "blur" },
    { type: "string", min: 3, message: "用户名不能小于4位", trigger: "blur" },
    { type: "string", max: 15, message: "用户名不能大于15位", trigger: "blur" }
  ],
  captcha: [
    { required: true, message: "验证码为必填选项", trigger: "blur" }
  ],
  password: [
    { required: true, message: "密码不能为空", trigger: "blur" },
    { type: "string", min: 4, message: "密码不能小于4位", trigger: "blur" },
    { type: "string", max: 12, message: "密码不能大于12位", trigger: "blur" }
  ],
  email: [
    { required: true, message: '邮箱不能为空', trigger: 'blur' },
    { type: 'email', message: '邮箱格式有误', trigger: 'blur' }
  ],
  Website: [ // 验证网址
    {
      validator: (rule, value, callback) => {
        if (value) {
          if (/(http|https):\/\/([\w.]+\/?)\S*/.test(value)) {
            callback();
          } else {
            callback("请输入正确的外链地址");
          }
        } else {
          callback();
        }
      }, trigger: "blur"
    }
  ],
  CreditCode: [ // 验证统一社会信用代码
    {
      validator: (rule, value, callback) => {
        if (!value) {
          callback();
        }
        var patrn = /^[0-9A-Z]+$/;
        //18位校验及大写校验
        if ((value.length != 18) || (patrn.test(value) == false)) {
          callback("请输入正确的统一社会信用代码");
        }
        else {
          var Ancode;//统一社会信用代码的每一个值
          var Ancodevalue;//统一社会信用代码每一个值的权重
          var total = 0;
          var weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28];//加权因子
          var str = '0123456789ABCDEFGHJKLMNPQRTUWXY';
          //不用I、O、S、V、Z
          for (var i = 0; i < value.length - 1; i++) {
            Ancode = value.substring(i, i + 1);
            Ancodevalue = str.indexOf(Ancode);
            total = total + Ancodevalue * weightedfactors[i];
            //权重与加权因子相乘之和
          }
          var logiccheckcode = 31 - total % 31;
          if (logiccheckcode == 31) {
            logiccheckcode = 0;
          }
          var Str = "0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y";
          var Array_Str = Str.split(',');
          logiccheckcode = Array_Str[logiccheckcode];
          var checkcode = value.substring(17, 18);
          if (logiccheckcode != checkcode) {
            callback("请输入正确的统一社会信用代码");
          } else {
            callback();
          }
          callback();
        }
      }, trigger: "blur"
    }
  ],
  BusinessLicenses: [ // 验证统一社会信用代码
    {
      validator: (rule, value, callback) => {
        if (!value) {
          callback();
        }
        if (value.length != 18 && value.length != 15) { 
          callback("请输入正确的营业执照");
        }
        //十八位新码
        if (value.length == 18) {
          var regex = /^([159Y]{1})([1239]{1})([0-9ABCDEFGHJKLMNPQRTUWXY]{6})([0-9ABCDEFGHJKLMNPQRTUWXY]{9})([0-90-9ABCDEFGHJKLMNPQRTUWXY])$/;
          if (!regex.test(value)) { 
            callback("请输入正确的营业执照");
          }
          var str = "0123456789ABCDEFGHJKLMNPQRTUWXY";
          var ws = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28];
          var codes = new Array();
          codes[0] = value.substr(0, value.length - 1);
          codes[1] = value.substr(value.length - 1, value.length);
          var sum = 0;
          for (var i = 0; i < 17; i++) {
            sum += str.indexOf(codes[0].charAt(i)) * ws[i];
          }
          var c18 = 31 - (sum % 31);
          if (c18 == 31) {
            c18 = 'Y';
          } else if (c18 == 30) {
            c18 = '0';
          }
          if (str.charAt(c18) != codes[1].charAt(0)) {

            callback("请输入正确的营业执照");
          }
          callback();

        }
        //十五位编码
        else {
          var ret = false;
          var sum = 0;
          var s = [];
          var p = [];
          var a = [];
          var m = 10;
          p[0] = m;
          for (var i = 0; i < value.length; i++) {
            a[i] = parseInt(value.substring(i, i + 1), m);
            s[i] = (p[i] % (m + 1)) + a[i];
            if (0 == s[i] % m) {
              p[i + 1] = 10 * 2;
            } else {
              p[i + 1] = (s[i] % m) * 2;
            }
          }
          if (1 == (s[14] % m)) {
            callback();
          } else {
            callback("请输入正确的营业执照");
          }
        }
      }, trigger: "blur"
    }
  ]
} 