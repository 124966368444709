<template>
  <div class="bindphone">
    <p>
      您正在使用<span v-if="loginType === 2">微信</span
      ><span v-if="loginType === 3">QQ</span>账号登录，请先绑定手机号
    </p>

    <Form
      class="F"
      ref="formInline1"
      :model="formInline1"
      :rules="ruleInline1"
      :label-width="80"
      @keyup.enter.native="handleSubmit1('formInline1')"
    >
      <FormItem class="F-1 phone_code" prop="phone" label="手机">
        <Input
          class="I"
          type="text"
          v-model="formInline1.phone"
          placeholder="手机号"
        />
      </FormItem>
      <FormItem class="F-1 phone_code" prop="code" label="验证码">
        <Input
          class="I"
          type="text"
          ref="captcha_input2"
          maxlength="6"
          v-model="formInline1.code"
          placeholder="验证码"
          autocomplete="off"
        />
        <Button v-if="showCode" @click="getPhoneCode">获取验证码</Button>
        <Button v-else disabled style="color: #ccc"
          >{{ count }}s后重新获取</Button
        >
      </FormItem>
      <FormItem style="margin-top: 40px">
        <Button
          type="primary"
          @click="handleSubmit1('formInline1')"
          size="large"
          :loading="loading"
          style="width: 100%"
          >确定</Button
        >
      </FormItem>
    </Form>
    <Modal v-model="show" footer-hide>
      <div class="f-tac">
        <div><Icon type="ios-alert" size="80" color="#fe5e00" /></div>
        <p style="margin: 30px 0">
          该手机已绑定其他用户信息<br />可直接前往登录
        </p>
        <div class="f-tac" style="margin: 10px">
          <Button style="width: 100px; margin-right: 20px" @click="show = false"
            >取消</Button
          >
          <Button
            style="width: 100px"
            type="primary"
            @click="$router.push(`/login/${exhibitionId}`)"
            >立即登录</Button
          >
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showTip"
      title="绑定遇到问题"
      @on-ok="tipCancel"
      @on-cancel="tipOk"
      ok-text="更换手机"
      cancel-text="跳过绑定"
    >
      <p style="color: red; margin-bottom: 10px">绑定遇到问题</p>
      <p>手机号（{{ formInline1.phone }}）已绑定其他账号</p>
      <div style="text-align: right">
        <a href="javascript:;" @click="loginNow">马上登录</a>
      </div>
    </Modal>
  </div>
</template>

<script>
import { validator } from "@/other/validator";
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "auth-bindphone",
  data() {
    return {
      showTip: false,
      register: false, // 未注册成功
      clear: true, // 没有绑定手机退出 清楚手机号和登录相关的状态
      show: false,
      loading: false,
      showCode: true,
      timer: null,
      count: "",
      codeKey: "",
      formInline1: {
        phone: "",
        code: "",
      },
      ruleInline1: {
        phone: validator.phone,
        code: [{ required: true, message: "验证码必填", trigger: "blur" }],
      },
    };
  },
  created() {
    this.setLogin(false);
  },
  destroyed() { 
    if (this.clear) {
      this.setLogin(false);
      this.setUser("");
      this.setUserId("");
      this.setMyAdvisorySetting(null);
      this.signOutOidc();
      this.signOutOidcQQ();
      this.setRedirectUrl(login.href + "/login/" + this.exhibitionId);
    }
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      token: "getToken",
      user: "getUser",
      loginType: "getLoginType",
    }),
  },
  methods: {
    ...mapMutations({
      setLoginType: "setLoginType",
      setUser: "setUser",
      setUserId: "setUserId",
      setMyAdvisorySetting:"setMyAdvisorySetting",
      setToken: "setToken",
      setTokenTime: "setTokenTime",
      setLogin: "setLogin",
      setExhibitionId: "setExhibitionId",
      setRedirectUrl: "setRedirectUrl",
    }),
    ...mapActions({
      signOutOidc: "signOutOidc",
      signOutOidcQQ: "oidcStoreQQ/signOutOidc",
      graphqlPost: "graphqlPost",
      getImgCode: "loginModule/getImgCode",
      getMsgCode: "getMsgCode",
      validateCode: "loginModule/validateCode",
      validatePhone: "loginModule/validatePhone",
      updatePhonenumber: "user/updatePhonenumber",
      getUserAdvisorySetting:"roleModule/getUserAdvisorySetting"
    }),
    async handleSubmit1(name) {
      let valid = await this.validForm(name);
      if (!valid) return;
      if (!this.formInline1.code) {
        this.$Message.warning("请获取验证码!");
        return;
      }
      let codeValid = await this.validPhoneCode();
      if (!codeValid) return;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 5000);

      const req = {
        memberId: this.user.inMember.memberId,
        phonenNumber: this.formInline1.phone,
      };

      let bool = await this.updatePhonenumber(req).catch((err) => {
        this.loading = false;
      });

      if (bool.result) {
        this.register = true;
        this.clear = false; // 绑定成功 不需要清楚登录信息
        this.$Message.success("绑定成功！");
        this.getUser();
      } else {
        if (bool.code === 0) {
          this.show = true;
        } else {
          this.$Message.error(bool.message);
        }
        this.formInline1.code = "";
        this.$refs.captcha_input2.focus();
      }
    },
    async getPhoneCode() {
      this.$refs.formInline1.validateField("phone", async (val) => {
        if (val !== "") return;
        let res = await this.validatePhone(this.formInline1.phone);
        if (res && res.data) {
          // this.$Message.error("该手机已绑定其他账号");
          this.showTip = true;
        } else {
          this.formInline1.code = "";
          this.$refs.captcha_input2.focus();
          this.downTime();
        }
      });
    },
    async downTime() {
      const valid = await this.getMsgCode(this.formInline1.phone);
      if (valid && valid.result) {
        this.codeKey = valid.data.key;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCode = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    validForm(name) {
      // 验证表单
      return new Promise((resolve, reject) => {
        this.$refs[name].validate((valid) => {
          resolve(valid);
        });
      });
    },
    async validPhoneCode() {
      // 验证code
      if (!this.codeKey || !this.formInline1.code) return false;
      //原手机认证->下一步 校验验证码，返回之后打开更换手机号弹框
      let valid = await this.validateCode({
        key: this.codeKey,
        code: this.formInline1.code,
      });
      if (!valid) {
        this.$Message.error("验证码错误，请重试！");
        this.formInline1.code = "";
        this.$refs.captcha_input.focus();
        this.getCode();
        return false;
      }
      return true;
    },
    async getCode() {
      // 获取code
      this.code = await this.getImgCode();
    },
    async getUser() {
      let query = `
        query{
          memberQuery{
            current{
              address
              avatarUrl
              balance
              birthday
              businessCard
              city
              company
              country
              county
              createAt
              deleteAt
              department
              email
              followCount
              id
              inMember(exhibitionId:"${this.exhibitionId}"){ 
                business
                category
                id 
                memberId
                originId
                originLang
                originRemark
                isBlacklist
                originType
                regAt
                sayCount
                sayUserCount
                userId
                userRole
                exhibitorId
                companyUsers{
                  id
                  isAppointment
                }
              }
              introduction
              isDeleted
              jobTitle
              name
              nickName
              phone
              phoneConfirm
              province
              talkCount
              talkSuccessedCount
              title
              updateAt
              userId
            }
          } 
        }
      `;
      let opt = {
        opt: {
          query: query,
        },
        token: this.token,
      };
      let data = await this.graphqlPost(opt);
      console.log(data, "用户信息");
      if (data.data && data.data.memberQuery && data.data.memberQuery.current) {
        this.setLogin(true);
        this.setUser(data.data.memberQuery.current);
        this.getUserAdvisorySetting();
        this.setUserId(data.data.memberQuery.current.userId);
        this.$router.push(`/home/index/${this.exhibitionId}`);
      }
    },
    tipOk() { 
      this.setLogin(true);
      this.clear = false; 
      this.$router.push(`/home/index/${this.exhibitionId}`); 
    },
    tipCancel() {
      this.formInline1.code = "";
      this.formInline1.phone = "";
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = null;
      this.count = "";
      this.codeKey = "";
      this.$refs["formInline1"].resetFields();
    },
    loginNow() {
      this.setRedirectUrl("");
      location.href = location.origin + "/login/" + this.exhibitionId;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.bindphone {
  width: 500px;
  margin: 200px auto 0;
  p {
    margin-bottom: 30px;
    padding-left: 140px;
  }
}
.F {
  display: flex;
  flex-direction: column;
  width: 400px;
  position: relative;
  margin: 0 auto;
  &-1 {
    margin-top: 34px;
    margin-bottom: 0;
    &.phone_code {
      ::v-deep {
        .ivu-form-item-content {
          display: flex;
          .ivu-btn {
            margin-left: 10px;
          }
        }
      }
    }
    .I {
      // margin-top: 19px;
    }
  }
  .F-2 {
    width: 100%;
    height: 15px;
    line-height: 15px;
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    .F-2-text {
      width: 48px;
      height: 13px;
      font-size: 12px;
      color: #333;
      cursor: pointer;
    }
  }
  .wx-btn {
    margin-top: 20px;
  }
  .code {
    width: 70px;
    height: 26px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    cursor: pointer;
  }
}
.icon-xinxi {
  font-size: 30px;
  cursor: pointer;
  &:hover {
    @include font_color(#1890ff);
  }
}
.c-m {
  margin-top: 30px;
  height: 600px;
  &-one {
    background-color: #fafafa;
    border: 1px solid rgba(221, 221, 221, 0.3);
    border-radius: 5px;
    margin-bottom: 15px;
    height: 100px;
    display: flex;
    cursor: pointer;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 1px solid #e6e6e6;
      margin-left: 20px;
      margin-top: 15px;
    }
    .one-r {
      width: calc(100% - 80px);
      height: 100%;
      padding-left: 20px;
      padding-top: 15px;
      padding-right: 10px;
      h2 {
        font-size: 16px;
        color: #262626;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      p {
        font-size: 14px;
        color: #8c8c8c;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.c-b {
  text-align: center;
}
.select-input {
  .icon-filter {
    position: absolute;
    right: 2px;
    top: 1px;
    cursor: pointer;
    font-size: 26px;
    color: #ccc;
  }
  .ex-list {
    position: absolute;
    background-color: #fff;
    z-index: 900;
    top: 35px;
    left: 0;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    border-radius: 4px;
    width: 100%;
    p {
      width: 100%;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #333;
      &:hover {
        background-color: #f3f3f3;
        cursor: pointer;
      }
    }
  }
}
.other {
  width: 100%;
  background: #fff;
  .title {
    font-size: 14px;
    color: #666;
    text-align: center;
    position: relative;
    margin: 19px 0 15px 0;
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 100px;
      height: 1px;
      background: #e6e6e6;
      top: 50%;
      margin-top: -1px;
    }
  }
  &-content {
    display: flex;
    align-items: center;
    .item {
      font-size: 14px;
      color: #666;
      margin-right: 10px;
      cursor: pointer;
      padding-right: 10px;

      display: flex;
      align-items: center;
      position: relative;
      &.s-1 {
        .iconfont {
          color: #49bd38;
        }
      }
      &.s-2 {
        .iconfont {
          color: #17abe3;
        }
      }
      .iconfont {
        font-size: 20px;
        display: inline-block;
        margin-right: 9px;
      }
      &:first-child {
        &::before {
          content: "";
          right: 0;
          top: 50%;
          margin-top: -10px;
          position: absolute;
          height: 20px;
          width: 1px;
          background: #e6e6e6;
        }
      }
    }
    .item-right {
      font-size: 14px;
      color: #ff5e00;
      flex: 1;
      text-align: right;
      span {
        cursor: pointer;
      }
    }
  }
}
</style>